import { render, staticRenderFns } from "./default.vue?vue&type=template&id=ff09618a"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopBar: require('/opt/build/repo/components/layout/TopBar.vue').default,FooterColumns: require('/opt/build/repo/components/layout/FooterColumns.vue').default,Copyright: require('/opt/build/repo/components/layout/Copyright.vue').default,ScCookieBar: require('/opt/build/repo/node_modules/@evince/site/lib/packages/CookieBar.vue').default})
