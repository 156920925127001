import { render, staticRenderFns } from "./Copyright.vue?vue&type=template&id=dd24fed8"
import script from "./Copyright.vue?vue&type=script&lang=js"
export * from "./Copyright.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/opt/build/repo/components/Logo.vue').default,ScSocialIcons: require('/opt/build/repo/node_modules/@evince/site/lib/packages/SocialIcons.vue').default})
