
export default {
  name: 'FooterColumns',
  async fetch() {
    const { $content, $i18n: { locale } } = this

    const data = await $content(locale, 'rooms')
      .only(['slug', 'name'])
      .sortBy('id')
      .fetch()

    this.rooms = data.map(({ slug, name }) => ({
      translate: false,
      label: name,
      path: { name: 'rooms-id', params: { id: slug } }
    }))
  },
  data() {
    return {
      rooms: [],
      quickLinks: [
        {
          path: 'rooms',
          label: 'pages.rooms',
          translate: true
        },
        {
          path: 'location',
          label: 'pages.location',
          translate: true
        },
        {
          path: 'guesthouse',
          label: 'pages.guesthouse',
          translate: true
        },
        // {
        //   path: 'cancellation-policy',
        //   label: 'pages.cancellationPolicy',
        //   translate: true
        // },
        {
          path: 'cookie-policy',
          label: 'site.cookiePolicy',
          translate: true
        }
      ]
    }
  }
}
