
export default {
  name: 'Logo',
  props: {
    height: {
      type: [String, Object],
      default: 'md'
    },
    mode: {
      type: String,
      default: null
    }
  },
  computed: {
    logo() {
      return this.mode == 'small' ? '/logo-small.svg' : '/logo.svg'
    }
  }
}
