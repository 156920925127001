export default async (ctx, inject) => {
  const options = {"el":{"icons":true,"email":"info@greyroomsnaxos.com","address":"Άγιος Γεώργιος, Νάξος 843 00","geoAddress":"Grey Rooms Naxos","phone":"+30 697 644 6103"},"en":{"icons":true,"email":"info@greyroomsnaxos.com","address":"Saint George, Naxos 843 00","geoAddress":"Grey Rooms Naxos","phone":"+30 697 644 6103"}}
  const attrs = Object.values(options)[0]

  const contact = {
    ...options,
    get details() {
      return this[ctx.app.i18n.vm.locale] || {}
    }
  }

  Object.keys(attrs).forEach(key => {
    Object.defineProperty(contact, key, {
      get() {
        return this.details[key]
      }
    })
  })

  inject('contact', contact)
}
