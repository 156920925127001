import { make } from 'vuex-pathify'

const tripDetails = {
  checkIn: null,
  checkOut: null,
  adults: 2,
  children: 0
}

const state = () => ({
  tripDetails: { ...tripDetails },
  requestRoom: null
})

const getters = make.getters(state)

const mutations = {
  ...make.mutations(state),

  RESET_REQUEST(state) {
    state.requestRoom = null

    Object.entries(tripDetails).forEach(([key, value]) => {
      state.tripDetails[key] = value
    })
  }
}

const actions = {
  ...make.actions(state),

  resetRequest({ commit }) {
    commit('RESET_REQUEST')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
