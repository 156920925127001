
export default {
  name: 'Copyright',
  vessel: {
    copyright: {
      fontSize: 'sm',
      fontWeight: 'light',
      color: 'grey-darkest',
      textAlign: 'center'
    },
    social: {
      fontSize: 'md',
      color: 'primary',
      marginY: 'sm',
      lg: {
        marginY: 0
      }
    }
  },
  data() {
    return {
      socialAccounts: [
        {
          name: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/Grey-Rooms-Naxos-2153490191439937/'
        }
      ]
    }
  },
  computed: {
    copyright() {
      const title = this.$t('footer.copyrightTitle')
      const rights = this.$t('footer.rightsReserved')

      return `${title}. ${rights}`
    }
  }
}
