
export default {
  name: 'TopBar',
  props: {
    stuck: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      links: [
        { label: 'pages.home', route: '/' },
        { label: 'pages.rooms', route: 'rooms' },
        { label: 'pages.location', route: 'location' },
        { label: 'pages.guesthouse', route: 'guesthouse' },
        { label: 'pages.contact', route: 'contact' }
      ]
    }
  },
  computed: {
    mode() {
      return !this.$screen.lg && 'small'
    }
  }
}
