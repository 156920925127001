import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _37645c87 = () => interopDefault(import('../pages/availability-request.vue' /* webpackChunkName: "pages/availability-request" */))
const _72e1af42 = () => interopDefault(import('../pages/cancellation-policy.vue' /* webpackChunkName: "pages/cancellation-policy" */))
const _08440926 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _69c9604b = () => interopDefault(import('../node_modules/@evince/site/lib/pages/cookie-policy.vue' /* webpackChunkName: "pages/site/cookie-policy" */))
const _621d649c = () => interopDefault(import('../pages/guesthouse.vue' /* webpackChunkName: "pages/guesthouse" */))
const _998f09c2 = () => interopDefault(import('../pages/location.vue' /* webpackChunkName: "pages/location" */))
const _63da8781 = () => interopDefault(import('../pages/rooms/index.vue' /* webpackChunkName: "pages/rooms/index" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7d1593c2 = () => interopDefault(import('../node_modules/@evince/site/lib/pages/request.vue' /* webpackChunkName: "pages/site/request" */))
const _47b67629 = () => interopDefault(import('../pages/rooms/_id.vue' /* webpackChunkName: "pages/rooms/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/availability-request",
    component: _37645c87,
    name: "availability-request___en"
  }, {
    path: "/cancellation-policy",
    component: _72e1af42,
    name: "cancellation-policy___en"
  }, {
    path: "/contact",
    component: _08440926,
    name: "contact___en"
  }, {
    path: "/cookie-policy",
    component: _69c9604b,
    name: "cookie-policy___en"
  }, {
    path: "/guesthouse",
    component: _621d649c,
    name: "guesthouse___en"
  }, {
    path: "/location",
    component: _998f09c2,
    name: "location___en"
  }, {
    path: "/rooms",
    component: _63da8781,
    name: "rooms___en"
  }, {
    path: "/",
    component: _0547af50,
    name: "index___en"
  }, {
    path: "/request/:id?",
    component: _7d1593c2,
    name: "request-id___en"
  }, {
    path: "/rooms/:id",
    component: _47b67629,
    name: "rooms-id___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
