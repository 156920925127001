
export default {
  name: 'FooterColumn',
  props: {
    title: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  vessel: {
    title: {
      fontWeight: 'normal',
      fontSize: 'md',
      marginBottom: 'md'
    },
    item: {
      fontWeight: 'light',
      marginBottom: 'sm'
    }
  },
  methods: {
    itemLabel(item) {
      if (item.translate)
        return this.$t(item.label)
      else
        return item.label
    }
  }
}
