export default async (ctx, inject) => {
  const openFrom = '01/05'
  const openTo = '30/09'

  const parseDate = (date) => {
    const today = new Date()
    const [day, month] = date.split('/')

    return `${today.getFullYear()}-${month}-${day}`
  }

  inject('hotel', {
    openFrom: parseDate(openFrom),
    openTo: parseDate(openTo)
  })
}
